// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-technology-js": () => import("./../../../src/components/technology.js" /* webpackChunkName: "component---src-components-technology-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-member-index-js": () => import("./../../../src/pages/member/index.js" /* webpackChunkName: "component---src-pages-member-index-js" */),
  "component---src-pages-service-index-js": () => import("./../../../src/pages/service/index.js" /* webpackChunkName: "component---src-pages-service-index-js" */),
  "component---src-pages-technology-index-js": () => import("./../../../src/pages/technology/index.js" /* webpackChunkName: "component---src-pages-technology-index-js" */),
  "component---src-pages-works-index-js": () => import("./../../../src/pages/works/index.js" /* webpackChunkName: "component---src-pages-works-index-js" */)
}

